@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;



p {
  color: #023047;  
 
  margin-bottom: 0px !important;
}

.markdown-body p {
  font-size: 16px;
}

.markdown-body.medium {
  font-size: 14px;
}

.markdown-body.medium p {
  font-size: 15px;
}

.markdown-body.transparent {
  font-size: 14px;
  background-color: unset;
}

.option .ant-checkbox {
  margin-top: 12px;
}

mjx-container svg{
  display: inline;
}

mjx-container[jax="SVG"][display="true"] {
    margin: 0.5rem 0 !important;
}

.panel-item.current {
  background-color: #fb5607;
  border-color: #fb5607;
  color: #ffffff;
}

.panel-item.visited {
  background-color: #f8fafc;
  border-color: #64748b;
}

.panel-item.marked {
  background-color: #edf7fb;
  border-color: #3b82f6;
  color: #3b82f6
}

.panel-item.saved {
  background-color: #f0fdfa;
  border-color: #14b8a6;
  color: #14b8a6;
}

.ant-menu-item-selected .icon {
  color:#fb5607
}

.ant-menu-item-selected .label {
  color:#fb5607;
  font-weight: 600;
}

.ant-menu-item {
  padding-inline: 28px 16px !important;
  display: flex !important;
  align-items: center;
}

.ant-card {
  margin-bottom: 16px;
}

/* Test CSS */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) { 
  .px-lg {
    padding-left: 16px;
    padding-right: 16px;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
  .px-lg {
    padding-left: 24px;
    padding-right: 24px;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  .px-lg {
    padding-left: 60px;
    padding-right: 60px;
  }
}

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {  }

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {  }